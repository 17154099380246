import React from "react";
import styled from "styled-components";
import { Link } from 'gatsby';
import Layout from "../components/Layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <NotFoundPageWrapper>
        <FlexWraper>
          <h1>
            Salon&nbsp;<span>Elizabeth</span>
          </h1>
          <h2>Niestety nie znaleziono podstrony</h2>
        </FlexWraper>
        <FlexWraper>
          <p>Wróć do strony głównej</p>
          <StyledLink to='/'>
            Powrót
          </StyledLink>
        </FlexWraper>
      </NotFoundPageWrapper>
    </Layout>
  )
}

export default NotFoundPage;

const FlexWraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
`

const NotFoundPageWrapper = styled.div`
  min-height: calc(100vh - 98px - 300px);
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  text-align: center;

  span {
    color: #D64ACE;
  }
`

const StyledLink = styled(Link)`
  all: unset;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  background-color: #D64ACE;
  width: 100px;
  height: 40px;
  cursor: pointer;
  margin: 20px;
`

